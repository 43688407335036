import React from "react";
import HeroPage from "./HeroPage";

const home = () => {
  return (
    <>
      <HeroPage />
      <div class="flex items-center whitespace-nowrap bg-[#22CDA3] overflow-hidden h-10 xl:h-14">
        <p class="text-xl xl:text-3xl text-white font-Orbitron leading-tight font-bold loop-text">
          Our sustainability journey is hitting new milestones! · Stay tuned as
          we navigate this continuous line of positive environmental impact, one
          breakthrough at a time!
        </p>
        <p class="text-xl xl:text-3xl text-white font-Orbitron leading-tight font-bold loop-text ms-3">
          Our sustainability journey is hitting new milestones! · Stay tuned as
          we navigate this continuous line of positive environmental impact, one
          breakthrough at a time!
        </p>
        <p class="text-xl xl:text-3xl text-white font-Orbitron leading-tight font-bold loop-text ms-3">
          Our sustainability journey is hitting new milestones! · Stay tuned as
          we navigate this continuous line of positive environmental impact, one
          breakthrough at a time!
        </p>
      </div>
      <div>
        {/* <div className="container mx-auto lg:mt-48 h-[40rem] xl:h-[50rem]">
          <div className="flex relative justify-center items-center">
            <img
              className="hidden lg:block lg:absolute top-0"
              src={require("../images/SustainableImpact.png")}
              alt="hero"
            />
            <div className="lg:ms-12 grid lg:grid-cols-3 mt-4 lg:px-0 px-5">
              <div className="flex flex-col col-span-2">
                <div className="md:text-4xl xl:text-6xl text-3xl flex  flex-wrap  font-Orbitron leading-tight font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#22CDA3] via-[#4A91C2] to-[#8A33F2]">
                  Sustainable Impact in a Token: CarbonOffset NFT Certificates
                </div>
                <div className="lg:absolute right-16 top-32 xl:right-40 xl:top-44 2xl:right-52 2xl:top-60 font-Montserrat text-xs xl:text-md leading-relaxed xl:leading-loose text-[#22CDA3] lg:w-1/3 xl:w-1/4 mt-10 text-center">
                  Unlock a new era of conscious ownership with CarbonOffsetting
                  Certificates. Each digital token represents a tangible
                  contribution to environmental sustainability, combining
                  cutting-edge blockchain technology for security. Join the
                  movement, own a piece of positive change.
                </div>
              </div>
              <div />
            </div>
          </div>
          <div className="lg:hidden mt-10 flex justify-center">
            <img
              className=""
              src={require("../images/Hero_2.png")}
              alt="hero"
            />
          </div>
        </div> */}
        <div className="relative mt-10 lg:mt-0">
          <div className="container mx-auto px-10">
            <div className="lg:grid grid-cols-2 items-center my-28 xl:gap-40 gap-10">
              <div className="col-start-1 lg:px-0 ">
                <div>
                  <div className="font-Orbitron text-2xl font-medium text-transparent bg-clip-text bg-gradient-to-r from-[#22CDA3] via-[#4A91C2] to-[#8A33F2]">
                    wCRBN NFTs
                  </div>
                  <div className="font-Orbitron font-bold lg:text-6xl text-3xl me-4 my-4 text-transparent bg-clip-text bg-gradient-to-r from-[#22CDA3] via-[#4A91C2] to-[#8A33F2]">
                    Sustainable Impact as an NFT
                  </div>
                  <div className="font-Montserrat text-sm my-4 text-white text-justify">
                    Embark on a transformative journey of eco-conscious
                    ownership through wCRBN NFTs. Each digital token signifies a
                    tangible commitment to environmental sustainability,
                    supported by verifiable on-chain metadata on our
                    EVM-compatible L1 platform. Join the movement and seize the
                    opportunity to participate in sustainable DeFi.
                  </div>
                  <div>
                    <a href="https://docs.viridis.network/overview/carbon-credit-architecture" target="_blank">
                        <button
                          type="button"
                          className=" flex items-center gap-2 tracking-wider font-Inter text-white bg-gradient-to-r to-[#8A33F2]  from-[#22CDA3] rounded-sm text-sm px-6 py-2 text-center"
                        >
                          Explore
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              d="M11.5 0.99601C11.4978 0.719877 11.2721 0.497813 10.996 0.500016L6.49615 0.535926C6.22002 0.53813 5.99795 0.763767 6.00016 1.0399C6.00236 1.31603 6.228 1.5381 6.50413 1.53589L10.504 1.50397L10.5359 5.50385C10.5381 5.77998 10.7638 6.00205 11.0399 5.99984C11.316 5.99764 11.5381 5.772 11.5359 5.49587L11.5 0.99601ZM1.35636 11.5116L11.3564 1.35072L10.6436 0.64928L0.643636 10.8102L1.35636 11.5116Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </a>
                  </div>
                </div>
              </div>
              <div className="col-start-2 lg:mt-0 mt-10">
                <img
                  src={
                    require("../images/Final_Selected_Draft_Mock.svg").default
                  }
                  alt="hero"
                />
              </div>
            </div>
          </div>
          <div className="hidden xl:block absolute bottom-0">
            <img src={require("../images/roundSide.png")} alt="hero" />
          </div>
        </div>
        <div className="container mx-auto lg:px-0 px-10">
          <div className="flex flex-col justify-center text-center 2xl:px-56">
            <div className="2xl:text-4xl text-3xl font-bold font-Orbitron leading-tight text-transparent bg-clip-text bg-gradient-to-r from-[#22CDA3] via-[#4A91C2] to-[#8A33F2]">
              How We Work
            </div>
            <p className="my-6 font-Montserrat text-sm md:text-base text-white text-justify">
              We are thrilled to keep our entire operational framework
              open-source as we define the gold standard to integrate the power
              of web3 with carbon offset. Suppliers provide metadata, openly
              auditable to be verified by any registered authority - this
              generates a wCRBN NFT, which serve as the gateway to using your
              offset in DeFi. Burn the NFT as a tangible testament to your
              project's commitment to sustainability, or convert to more
              fungible token versions for more precise trading and usage.
            </p>
          </div>
        </div>
        <div className="flex justify-end ">
          <div className="bg-[#22CDA31C] container my-10 lg:w-4/5 ms-5 pl-2 lg:rounded-s-full rounded-s-3xl py-10">
            <div className="hidden lg:grid grid-cols-6">
              <div className="col-start-1 col-span-1 flex lg:justify-center items-center ">
                <img
                  src={require("../images/01.png")}
                  alt="1"
                  className="h-20"
                />
              </div>
              <div className="col-start-2 col-span-4 ">
                <div>
                  <div className="2xl:text-xl text-base font-extrabold text-[#22CDA3]">
                    Sustainable Project Selection
                  </div>
                  <div className="font-Montserrat text-sm 2xl:text-base 2xl:me-56 my-4 text-white text-justify">
                    A variety of sustainable initiatives, from reforestation and
                    renewable energy to distributing sustainable cookware, can
                    integrate into the VRD ecosystem. By decentralizing the
                    process of validation and verification, there is no
                    centralized bottleneck on the creation of offsets. Instead,
                    the dynamic audit pipeline enabled by our chain allow each
                    offset to be assessed on its own merits.
                  </div>
                </div>
                 
              </div>
              <div className="col-start-6 col-span-2 flex items-center justify-center">
                <img
                  src={require("../images/si-glyph_connect-2.png")}
                  alt="2"
                  className="h-20"
                />
              </div>
            </div>
            <div className="lg:hidden flex justify-between px-5">
              <div>
                <img
                  src={require("../images/01.png")}
                  alt="1"
                  className="h-20"
                />
              </div>
              <div>
                <img
                  src={require("../images/si-glyph_connect-2.png")}
                  alt="2"
                  className="h-20"
                />
              </div>
            </div>
            <div className="lg:hidden mt-5 px-4">
              <div className="text-base font-extrabold text-[#22CDA3]">
                Sustainable Project Selection
              </div>
              <div className="font-Montserrat text-sm 2xl:text-base 2xl:me-56 my-4 text-white text-justify">
                A variety of sustainable initiatives, including reforestation
                and renewable energy projects, can integrate into the Viridis
                ecosystem by providing their carbon credit support data on our
                EVM-compatible L1 chain. We meticulously assess these projects
                to guarantee they contribute significantly to environmental
                well-being.
              </div>
            </div>
          </div>
        </div>
        <div className="pr-5">
          <div className="bg-[#22CDA31C] container my-10 lg:w-4/5 pr-5 lg:rounded-e-full mr-5 rounded-e-3xl py-10">
            <div className="hidden lg:grid grid-cols-6">
              <div className=" col-span-1 flex justify-center items-center">
                <img
                  src={require("../images/carbon_chart-combo-stacked.png")}
                  alt="2"
                  className="h-20"
                />
              </div>
              <div className=" col-span-4 text-right">
                <div>
                  <div className="2xl:text-xl text-base font-extrabold text-[#22CDA3]">
                    wCRBN Issuance
                  </div>
                  <div className="font-Montserrat text-sm 2xl:text-base  mt-3 text-white text-justify">
                    Upon the success of our supported projects, we issue digital
                    certificates in the form of NFTs, encapsulating the positive
                    environmental impact achieved. These unique tokens represent
                    tangible ownership of Viridis, creating a direct link
                    between your commitment and real-world results.
                  </div>
                </div>
              </div>
              <div className="ml-5 col-span-1 flex justify-center items-center">
                <img
                  src={require("../images/02.png")}
                  alt="1"
                  className="h-20"
                />
              </div>
            </div>
            <div className="lg:hidden flex justify-between px-5">
              <div>
                <img
                  src={require("../images/02.png")}
                  alt="1"
                  className="h-20"
                />
              </div>
              <div>
                <img
                  src={require("../images/carbon_chart-combo-stacked.png")}
                  alt="2"
                  className="h-20"
                />
              </div>
            </div>
            <div className="lg:hidden mt-5 px-4">
              <div className="text-base font-extrabold text-[#22CDA3]">
                wCRBN Issuance
              </div>
              <div className="font-Montserrat text-sm 2xl:text-base  mt-3 text-white text-justify">
                Upon the success of our supported projects, we issue digital
                certificates in the form of NFTs, encapsulating the positive
                environmental impact achieved. These unique tokens represent
                tangible ownership of Viridis, creating a direct link between
                your commitment and real-world results.
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end ">
          <div className="bg-[#22CDA31C] container my-10 lg:w-4/5 ms-5 pl-2 lg:rounded-s-full rounded-s-3xl py-10">
            <div className="hidden lg:grid grid-cols-6">
              <div className="col-start-1 col-span-1 flex justify-center items-center">
                <img
                  src={require("../images/03.png")}
                  alt="1"
                  className="h-20"
                />
              </div>
              <div className="col-start-2 col-span-4">
                <div>
                  <div className="2xl:text-xl text-base font-extrabold text-[#22CDA3]">
                    Blockchain Technology
                  </div>
                  <div className="font-Montserrat text-sm 2xl:text-base 2xl:me-56 my-4 text-white text-justify">
                    Through our innovative application of blockchain technology,
                    we guarantee the security, transparency, and authenticity of
                    every wCRBN NFT by anchoring each NFT with on-chain
                    distinguishable credentials within its metadata. The
                    tamper-resistant nature of blockchain ensures that your
                    digital asset is verifiable, unique, and safeguarded against
                    any risk of double spending.
                  </div>
                </div>
              </div>
              <div className="col-start-6 col-span-2 flex items-center justify-center">
                <img
                  src={require("../images/grommet-icons_technology.png")}
                  alt="2"
                  className="h-20"
                />
              </div>
            </div>
            <div className="lg:hidden flex justify-between px-5">
              <div>
                <img
                  src={require("../images/03.png")}
                  alt="1"
                  className="h-20"
                />
              </div>
              <div>
                <img
                  src={require("../images/grommet-icons_technology.png")}
                  alt="2"
                  className="h-20"
                />
              </div>
            </div>
            <div className="lg:hidden mt-5 px-4">
              <div className="text-base font-extrabold text-[#22CDA3]">
                Blockchain Technology
              </div>
              <div className="font-Montserrat text-sm 2xl:text-base 2xl:me-56 my-4 text-white text-justify">
                Through our innovative application of blockchain technology, we
                guarantee the security, transparency, and authenticity of every
                wCRBN NFT by anchoring each NFT with on-chain distinguishable
                credentials within its metadata. The tamper-resistant nature of
                blockchain ensures that your digital asset is verifiable,
                unique, and safeguarded against any risk of double spending.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden absolute right-0 lg:flex justify-end">
        <img src={require("../images/Frame_2.png")} alt="homeImg" />
      </div>
      <div className="container mx-auto relative justify-center mt-10">
        <div className="flex flex-col justify-center text-center 2xl:px-56 p-10">
          <div className="lg:text-4xl text-3xl font-Orbitron leading-tight text-transparent bg-clip-text bg-gradient-to-r from-[#22CDA3] via-[#4A91C2] to-[#8A33F2]">
            Journey to Carbon Neutrality
          </div>
          {/* <p className="my-6 font-Montserrat text-[#22CDA3] text-sm md:text-base ">
            Embark on the EcoToken Certification Journey—a seamless process where your transition to
            positive impact begins with token acquisition. From streamlined certificate requests to
            the realization of your environmental contribution with the EcoToken-WnCRBN, this
            section guides you through every step of your sustainable ownership experience. Join us
            in making a meaningful difference towards a greener future.
          </p> */}
        </div>
        <div className="2xl:ps-28 ">
          <div className="flex items-center relative lg:justify-normal justify-center md:my-0 my-4">
            <div className=" bg-[#22CDA317] rounded-3xl lg:w-1/3 md:w-2/4 px-10 lg:mx-0 md:mx-0 mx-5  py-12 relative z-10">
              <div className="flex flex-col justify-center text-center ">
                <div className="font-Poppins font-semibold text-white text-base font-extrabold">
                  Purchase wCRBN
                </div>
                <div className="font-Montserrat text-justify text-sm 2xl:text-base text-[#22CDA3] mt-2 ">
                  Using the on-chain native gas token $VRD, you will be able to
                  trade the divisible (by each token type) wCRBN NFTs attributed
                  with on-chain metadata.
                </div>
              </div>
            </div>
            <div>
              <img
                className="static hidden lg:block md:block -top-5 -right-5 z-10"
                src={require("../images/Humaaans_Standing_1.png")}
                alt="homeImg"
              />
            </div>
            <div className="absolute hidden lg:block  top-72 2xl:left-60 left-40 z-0">
              <img src={require("../images/String.png")} alt="homeImg" />
            </div>
          </div>
          <div className="flex relative items-center lg:justify-end justify-center 2xl:mt-24 lg:mt-32 2xl:mr-28 z-10 md:my-0 my-4">
            <div className="z-10 hidden lg:block md:block">
              <img
                src={require("../images/Humaaans_Standing_2.png")}
                alt="homeImg"
              />
            </div>
            <div className=" bg-[#22CDA317] rounded-3xl lg:w-1/3 md:w-1/2 lg:mx-0 md:mx-0 mx-5 px-10 py-12  lg:-ml-16">
              <div className="flex flex-col justify-center text-center ">
                <div className="font-Poppins font-semibold text-white text-base font-extrabold">
                  Mint bCRBN
                </div>
                <div className="font-Montserrat text-justify text-sm 2xl:text-base text-[#22CDA3] mt-2">
                  With your wCRBN NFTs, you can then mint a quantity-specific
                  bCRBN NFT. The bCRBN acts as a public certificate representing
                  your ultimate ownership of digitized carbon credits.
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center lg:justify-normal justify-center relative lg:my-0 md:my-20">
            <div className="z-20 hidden lg:flex md:flex  lg:order-1 order-2 lg:static md:absolute right-0">
              <img
                src={require("../images/Humaaans_Standing_3.png")}
                alt="homeImg"
              />
            </div>
            <div className=" bg-[#22CDA317] rounded-3xl lg:w-1/3 md:w-1/2 px-10 py-12 lg:-ml-28 lg:mx-0 md:mx-0 mx-5  relative z-10 lg:order-2 order-1">
              <div className="flex flex-col justify-center text-center ">
                <div className="font-Poppins font-semibold text-white text-base font-extrabold">
                  Become Carbon Neutral
                </div>
                <div className="font-Montserrat text-justify text-sm 2xl:text-base text-[#22CDA3] mt-2">
                  Since you have retired the carbon credits in wCRBN NFT by
                  minting a bCRBN, you have reduced your carbon footprint!
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block absolute 2xl:left-[32rem] xl:left-[22rem] left-72 bottom-60 z-0">
            <img src={require("../images/String3.png")} alt="homeImg" />
          </div>

{/* 
          <div className="flex items-center lg:justify-end justify-center 2xl:pe-96 lg:pe-40 relative md:my-0 my-4">
            <div className="bg-gradient-to-r to-[#8A33F2]  from-[#22CDA3] font-semibold  p-1 rounded-3xl w-[20rem]">
              <div className="grid grid-cols-2 w-full bg-white text-black rounded-3xl items-center text-center">
                <div>
                  <img
                    src={require("../images/Draft-3.svg").default}
                    alt="hero"
                  />
                </div>
                <div className="font-Montserrat  text-justify sm:text-center p-[10px] sm:p-[0px] text-gray-500 text-xs leading-tight font-light ">
                  Together, they synergize their roles to bring forth your
                  EcoToken-WnCRBN certificate—a collective effort in creating a
                  tangible testament to your commitment to a sustainable future.
                </div>
              </div>
            </div>
          </div> */}

          <div className="flex relative items-center lg:justify-end justify-center lg:py-[0px] md:pt-[100px] py-0  2xl:mr-28 z-10 md:my-0 my-4">
            <div className="z-20 hidden lg:flex md:flex lg:order-1 order-2 lg:static md:absolute right-0">
              <img
                src={require("../images/Humaaans_Standing_3.png")}
                alt="homeImg"
              />
            </div>
            <div className=" bg-[#22CDA317] rounded-3xl lg:w-1/3 md:w-1/2 px-10 py-12 lg:-ml-28 lg:mx-0 md:mx-0 mx-5  relative z-10 lg:order-2 order-1">
              <div className="flex flex-col justify-center text-center ">
                <div className="font-Poppins font-semibold text-white text-base font-extrabold">
                  Carbon Offset Metadata Certificate
                </div>
                <div className="font-Montserrat text-justify text-sm 2xl:text-base text-[#22CDA3] mt-2">
                  Together, they synergize their roles to bring forth your
                  EcoToken-WnCRBN certificate—a collective effort in creating a
                  tangible testament to your commitment to a sustainable future.
                </div>
              </div>
            </div>
          </div>

          <div className="hidden lg:block absolute mx-10 2xl:right-96 right-36 bottom-96">
            <img src={require("../images/String2.png")} alt="homeImg" />
          </div>
        </div>
      </div>
      <div className="container w-full xl:mx-auto mb-44 mt-44">
        <div className="flex flex-col justify-center gap-4">
          <div className="flex justify-center">
            <p className="text-6xl font-extrabold font-Orbitron leading-normal text-transparent bg-clip-text bg-gradient-to-r from-[#22CDA3] via-[#4A91C2] to-[#8A33F2]">
              RoadMap
            </p>
          </div>
          <div className="flex justify-center">
            <p className="text-lg text-center leading-8 text-Montserrat text-white">
              Embark on our journey to sustainability through the
              CarbonOffsetting Roadmap. This roadmap outlines our strategic
              milestones, guiding both our team and users toward a future where
              positive environmental impact is seamlessly integrated into every
              step. Join us as we navigate the path to a greener world together.
            </p>
          </div>
          <div className="mt-12 xxl:px-0 px-5 flex flex-col items-center">
            <div className="hidden lg:grid grid-cols-2 xl:gap-x-32 gap-x-10 w-2/3 justify-center">
              <div className="rounded-3xl bg-[#22CDA317] flex items-center justify-center px-6 py-16 relative">
                <div className="flex flex-col gap-3">
                  <p className="text-2xl font-medium font-Poppins text-center text-white">
                    {" "}
                    Creation of Viridis Chain{" "}
                  </p>
                  <div className="text-center text-[#22CDA3] text-base flex flex-col">
                    <span>• Viridis L2 Testnet</span>
                    <span>• Viridis EVM Testnet Staking and Airdrop</span>
                    <span>
                      • Onboard Consistent Suppliers of Carbon Offsets
                    </span>
                  </div>
                </div>
                <div className="flex justify-center absolute right-1/2 -bottom-10 left-1/2">
                  <div className="flex rounded-full border-2 border-[#FFF] bg-[#22CDA3] px-8 py-4  justify-center items-center ">
                    <span className="text-[#FFF] text-[32px] font-extrabold">
                      2
                    </span>
                  </div>
                </div>
                <div className=" flex justify-center absolute right-1/2 -bottom-14 text-[#22CDA3]">
                  |
                </div>
              </div>
              <div className="rounded-3xl bg-[#22CDA317] flex items-center justify-center px-6 py-16 relative">
                <div className="flex flex-col gap-3">
                  <p className="text-2xl font-medium font-Poppins text-center text-white">
                    {" "}
                    Creation of Viridis NFT Marketplace{" "}
                  </p>
                  <div className="text-center text-[#22CDA3] text-base flex flex-col">
                    <span>• Creation of NFT Marketplace DApp</span>
                    <span>
                      • Onboarding of derivative products created by other
                      parties
                    </span>
                  </div>
                </div>
                <div className="flex justify-center absolute right-1/2 -bottom-10 left-1/2">
                  <div className="flex rounded-full border-2 border-[#FFF] bg-[#22CDA3] px-8 py-4  justify-center items-center ">
                    <span className="text-[#FFF] text-[32px] font-extrabold">
                      4
                    </span>
                  </div>
                </div>
                <div className=" flex justify-center absolute right-1/2 -bottom-14 text-[#22CDA3]">
                  |
                </div>
              </div>
            </div>

            <hr className="hidden lg:block w-full border border-[#22CDA3] my-14" />
            <div className="hidden lg:grid grid-cols-3 xl:gap-x-28 gap-x-10">
              <div className="rounded-3xl bg-[#22CDA317] flex items-center justify-center px-6 py-10 relative">
                <div className="flex flex-col gap-3">
                  <p className="text-2xl font-medium font-Poppins text-center text-white">
                    {" "}
                    Viridis Launch{" "}
                  </p>
                  <div className="text-center text-[#22CDA3] text-base flex flex-col">
                    <span>• Viridis ERC20 Token Release and whitepaper</span>
                    <span>• Viridis at cop28</span>
                  </div>
                </div>
                <div className="flex justify-center absolute right-1/2 -top-10 left-1/2">
                  <div className="flex rounded-full border-2 border-[#FFF] bg-[#22CDA3] px-8 py-4  justify-center items-center ">
                    <span className="text-[#FFF] text-[32px] font-extrabold">
                      1
                    </span>
                  </div>
                </div>
                <div className=" flex justify-center absolute right-1/2 -top-16 text-[#22CDA3]">
                  |
                </div>
              </div>
              <div className="rounded-3xl bg-[#22CDA317] flex items-center justify-center px-6 py-16 relative">
                <div className="flex flex-col gap-3">
                  <p className="text-2xl font-medium font-Poppins text-center text-white">
                    Viridis L1 Mainnet Launch
                  </p>
                  <div className="text-center text-[#22CDA3] text-base flex flex-col">
                    <span>• Bridging and Staking</span>
                    <span>
                      • Minting Of Wrapped Carbon In Exchange For Carbon Credits
                    </span>
                  </div>
                </div>
                <div className="flex justify-center absolute right-1/2 -top-10 left-1/2">
                  <div className="flex rounded-full border-2 border-[#FFF] bg-[#22CDA3] px-8 py-4  justify-center items-center ">
                    <span className="text-[#FFF] text-[32px] font-extrabold">
                      3
                    </span>
                  </div>
                </div>
                <div className=" flex justify-center absolute right-1/2 -top-16 text-[#22CDA3]">
                  |
                </div>
              </div>
              <div className="rounded-3xl bg-[#22CDA317] flex items-center justify-center px-6 py-16 relative">
                <div className="flex flex-col gap-3">
                  <p className="text-2xl font-medium font-Poppins text-center text-white">
                    {" "}
                    Global Distribution Of Companies{" "}
                  </p>
                  <div className="text-center text-[#22CDA3] text-base flex flex-col">
                    <span>
                      • Creating Hubs Around The World Allowing Carbon Credits
                      From Various Authorities To Be Used Worldwide
                    </span>
                    <span>
                      • Partnership With Certificate Authority For Production Of
                      Own Carbon Credits
                    </span>
                  </div>
                </div>
                <div className="flex justify-center absolute right-1/2 -top-10 left-1/2">
                  <div className="flex rounded-full border-2 border-[#FFF] bg-[#22CDA3] px-8 py-4  justify-center items-center ">
                    <span className="text-[#FFF] text-[32px] font-extrabold">
                      5
                    </span>
                  </div>
                </div>
                <div className=" flex justify-center absolute right-1/2 -top-16 text-[#22CDA3]">
                  |
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:hidden grid gap-16">
          <div className="flex justify-center">
            <div className="rounded-3xl bg-[#22CDA317] flex items-center px-6 py-16 relative w-2/3">
              <div className="flex flex-col gap-3 w-full">
                <p className="text-2xl font-medium font-Poppins text-center text-white">
                  {" "}
                  Viridis Launch{" "}
                </p>
                <div className="text-center text-[#22CDA3] text-base 2xl:px-10 px-0 flex flex-col">
                  <span>• Viridis ERC20 Token Release and whitepaper</span>
                  <span>• Viridis at cop28</span>
                </div>
              </div>
              <div className="flex justify-center absolute right-1/2 -top-10 left-1/2">
                <div className="flex rounded-full border-2 border-[#FFF] bg-[#22CDA3] px-8 py-4  justify-center items-center ">
                  <span className="text-[#FFF] text-[32px] font-extrabold">
                    1
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex justify-center">
              <div className="rounded-3xl bg-[#22CDA317] flex items-center px-6 py-16 relative w-2/3">
                <div className="flex flex-col gap-3 w-full">
                  <p className="text-2xl font-medium font-Poppins text-center text-white">
                    {" "}
                    Creation of Viridis Chain{" "}
                  </p>
                  <div className="text-center text-[#22CDA3] text-base flex flex-col">
                    <span>• Viridis L2 Testnet</span>
                    <span>• Viridis EVM Testnet Staking and Airdrop</span>
                    <span>
                      • Onboard Consistent Suppliers of Carbon Offsets
                    </span>
                  </div>
                </div>
                <div className="flex justify-center absolute right-1/2 -top-10 left-1/2">
                  <div className="flex rounded-full border-2 border-[#FFF] bg-[#22CDA3] px-8 py-4  justify-center items-center ">
                    <span className="text-[#FFF] text-[32px] font-extrabold">
                      2
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex justify-center">
              <div className="rounded-3xl bg-[#22CDA317] flex items-center px-6 py-16 relative w-2/3">
                <div className="flex flex-col gap-3 w-full">
                  <p className="text-2xl font-medium font-Poppins text-center text-white">
                    {" "}
                    Viridis L1 Mainnet Launch{" "}
                  </p>
                  <div className="text-center text-[#22CDA3] text-base 2xl:px-10 px-0 flex flex-col">
                    <span>• Bridging and Staking</span>
                    <span>
                      • Minting Of Wrapped Carbon In Exchange For Carbon Credits
                    </span>
                  </div>
                </div>
                <div className="flex justify-center absolute right-1/2 -top-10 left-1/2">
                  <div className="flex rounded-full border-2 border-[#FFF] bg-[#22CDA3] px-8 py-4  justify-center items-center ">
                    <span className="text-[#FFF] text-[32px] font-extrabold">
                      3
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex justify-center">
              <div className="rounded-3xl bg-[#22CDA317] flex items-center px-6 py-16 relative w-2/3">
                <div className="flex flex-col gap-3 w-full">
                  <p className="text-2xl font-medium font-Poppins text-center text-white">
                    {" "}
                    Creation of Viridis NFT Marketplace{" "}
                  </p>
                  <div className="text-center text-[#22CDA3] text-base px-0 flex flex-col">
                    <span>• Creation of NFT Marketplace DApp</span>
                    <span>
                      • Onboarding of derivative products created by other
                      parties
                    </span>
                  </div>
                </div>
                <div className="flex justify-center absolute right-1/2 -top-10 left-1/2">
                  <div className="flex rounded-full border-2 border-[#FFF] bg-[#22CDA3] px-8 py-4  justify-center items-center ">
                    <span className="text-[#FFF] text-[32px] font-extrabold">
                      4
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex justify-center">
              <div className="rounded-3xl bg-[#22CDA317] flex items-center px-6 py-16 relative w-2/3">
                <div className="flex flex-col gap-3 w-full">
                  <p className="text-2xl font-medium font-Poppins text-center text-white">
                    {" "}
                    Global Distribution Of Companies{" "}
                  </p>
                  <div className="text-center text-[#22CDA3] text-base flex flex-col">
                    <span>
                      • Creating Hubs Around The World Allowing Carbon Credits
                      From Various Authorities To Be Used Worldwide
                    </span>
                    <span>
                      • Partnership With Certificate Authority For Production Of
                      Own Carbon Credits
                    </span>
                  </div>
                </div>
                <div className="flex justify-center absolute right-1/2 -top-10 left-1/2">
                  <div className="flex rounded-full border-2 border-[#FFF] bg-[#22CDA3] px-8 py-4  justify-center items-center ">
                    <span className="text-[#FFF] text-[32px] font-extrabold">
                      5
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default home;
