import React from "react";

const whyViridis = () => {
  return (
    <>
      <div className="relative">
        <div className="container mx-auto py-36 xl:px-72 lg:px-56 md:px-32 relative">
          <div className="font-Orbitron text-center font-black lg:text-5xl text-3xl text-transparent bg-clip-text bg-gradient-to-r from-[#22CDA3] via-[#4A91C2] to-[#8A33F2] ">
            Why Viridis
          </div>
          <div className="text-md text-gray-300 text-center font-Montserrat mt-5 leading-loose">
            Viridis leverages Web3 technology to revolutionize the Voluntary Carbon Markets (VCM),
            addressing current issues of opacity, excessive intermediaries, and low liquidity. Our
            innovative solution aims to streamline capital flows, accelerating support for
            high-impact carbon mitigation and removal projects globally.
          </div>
          <div className="absolute top-0 right-0">
            <img src={require("../images/section_decoration.png")} alt="hero" />
          </div>
        </div>
        <div className="hidden md:block absolute top-10">
          <img src={require("../images/top_circle.png")} alt="hero" />
        </div>
      </div>
      <div className="container mx-auto lg:my-10">
        <div className="lg:text-3xl text-xl text-center text-white font-Orbitron xl:px-48 lg:px-24">
          Viridis Embarks on a Mission to Integrate DeFi and the Voluntary Carbon Market (VCM).
        </div>
        <div className="ml-20">
          <img src={require("../images/TwoSquare.png")} alt="hero" />
        </div>
        <div className="lg:grid grid-cols-2 items-center xl:ml-0 lg:ml-6 ml-0 lg:px-0 px-8">
          <div className="font-Montserrat text-md text-[#22CDA3] flex justify-center lg:text-left text-center leading-7 lg:ps-5 ps-0 ">
            Empowering participants in the Regenerative Finance (ReFi) economy, Viridis offers a
            decentralized, user-friendly Web3 infrastructure. Here, ReFi signifies a commitment to
            the environment and climate, advocating for the integration of carbon costs into the
            realm of DeFi.
          </div>
          <div className="flex justify-center">
            <img src={require("../images/language-icon.png")} alt="hero" />
          </div>
        </div>
      </div>
      <div className="container mx-auto my-10 relative">
        <div className="xl:text-3xl lg:text-xl text-center text-white font-Orbitron xl:px-48 lg:px-24 px-3">
          In propelling the advancement of environmental finance and seizing opportunities, we're
          actively crafting three essential products.
        </div>
        <div className="w-2/3 flex justify-center absolute top-10">
          <img src={require("../images/TwoSquare.png")} alt="hero" />
        </div>
        <div className="lg:grid grid-cols-2 my-28 items-center">
          <div className="flex justify-center">
            <img src={require("../images/5310w32698.png")} alt="hero" />
          </div>
          <div className="">
            <div className="grid grid-cols-10 lg:px-0 md:px-10 px-5">
              <div></div>
              <div className="font-Montserrat font-bold text-md text-[#22CDA3] leading-7 col-span-8 text-justify">
                Urgently and decisively addressing climate change is crucial for the survival of our
                planet.
              </div>
            </div>
            <div></div>
            <div className="grid grid-cols-10 items-center lg:px-0 md:px-10 px-5">
              <div className="h-4 w-4 bg-[#22CDA3] rotate-45"></div>
              <div className="font-Montserrat text-md text-gray-400 leading-7 col-span-8 py-5 text-justify">
                Establishing a comprehensive and tokenized framework for the decentralized
                verification, classification, and ownership of carbon credits through the harnessing
                of blockchain technology
              </div>
            </div>
            <div className="grid grid-cols-10 items-center lg:px-0 md:px-10 px-5">
              <div className="h-4 w-4 bg-[#22CDA3] rotate-45"></div>
              <div className="font-Montserrat text-md text-gray-400 leading-7 col-span-8 py-5 text-justify">
                Facilitating direct engagement with the governance process of Viridis while
                incorporating tokens to incentivize active participation.
              </div>
            </div>
            <div className="grid grid-cols-10 items-center lg:px-0 md:px-10 px-5">
              <div className="h-4 w-4 bg-[#22CDA3] rotate-45"></div>
              <div className="font-Montserrat text-md text-gray-400 leading-7 col-span-8 py-7 text-justify">
                Introducing a Project Development Gateway to scale supply in the VCM.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative xl:mt-0 md:mt-44">
        <div className="absolute -top-64 z-0 right-0 rotate-180 hidden md:block">
          <img src={require("../images/top_circle.png")} alt="hero" />
        </div>
        <div className="z-10 relative bg-gradient-to-r from-[#22CDA3] via-[#4A91C2]  to-[#8A33F2]  flex flex-col justify-center items-center py-5">
          <div className="text-center font-Orbitron text-white font-black xl:text-3xl md:text-xl my-4">
            The era of carbon markets has arrived – are you prepared?
          </div>
          <div className="text-white font-Orbitron text-sm lg:text-md  rounded-lg border-4 inline-flex px-5 py-3">{`Let's proceed ->  `}</div>
        </div>
      </div>
    </>
  );
};

export default whyViridis;
