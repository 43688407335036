import React, { useRef } from "react";
import { toast } from "react-toastify";
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [user, setUser] = React.useState({
    name: "",
    email: "",
    message: "",
  });

  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const status = valid(user);
    if (status) {
        emailjs.sendForm('service_qrmk6b2', 'template_sq0a46c', form.current, {
            publicKey: 'ON_3xBencMfQ5g6g-',
        })
        .then(() => {
            toast.success("Your message has been sent successfully!", {
              theme: "colored",
            });
          },
          (error) => {
            toast.error("Something went wrong!");
            console.log("Error :>> ", error.text);
          },
        );
      // const response = await fetch(`${process.env.BASE_URL}/api/add-user`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(user),
      // });
      // if (response.ok) {
      //   setUser({
      //     name: "",
      //     email: "",
      //     message: "",
      //   });
      //   toast.success("Your message has been sent successfully!", {
      //     theme: "colored",
      //   });
      // } else {
      //   toast.error("Something went wrong!");
      //   console.log("Error :>> ", response);
      // }
    }
  };

  const valid = (obj) => {
    let flag = true;
    Object.values(obj).forEach((val) => {
      if (!val || val.trim() === "") {
        flag = false;
      }
    });

    if (!flag) {
      toast.error("Please fill all the fields");
    } else {
      if (!/\S+@\S+\.\S+/.test(obj.email)) {
        flag = false;
        toast.error("Please enter a valid email address");
      }
    }
    return flag;
  };

  return (
    <>
      <img
        className="w-full absolute h-dvh"
        // style={{ height: "100%" }}
        src={require("../images/BG_Effects.png")}
        alt="hero"
      />
      <div className="container mx-auto">
        <div className="flex relative justify-center items-center h-screen">
          <div className="w-full lg:grid grid-cols-2">
            <div className="flex flex-col mt-0 md:mt-8 mx-8">
              <div className="lg:text-5xl text-4xl xl:text-7xl flex flex-wrap  font-Orbitron leading-tight font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#22CDA3] via-[#4A91C2] to-[#8A33F2]">
                <span className="">Feel Free to</span>
                <span className="">write us</span>
              </div>
            </div>
            <div className="border-2 border-[#25CE9E70] rounded-3xl p-5 bg-[#000] shadow-[0_5px_15px_rgba(37,206,158,0.44)] mx-8">
              <form ref={form} onSubmit={handleSubmit} className="md:mt-12 mt-6 mb-2 md:mx-8 mx-2">
                <div className="flex flex-col md:flex-row w-full mb-4">
                  <div className="bg-gradient-to-r to-[#22CDA3]  from-[#8A33F2] text-white font-semibold rounded p-[2px] w-full md:w-1/2 me-0 md:me-2 mb-2 md:mb-0">
                    <input
                      type="text"
                      onChange={handleChange}
                      name="name"
                      placeholder="Name"
                      value={user?.name || ""}
                      className="placeholder:text-[#6D6C6C] flex w-full bg-black text-white rounded p-3"
                    />
                  </div>
                  <div className="bg-gradient-to-r to-[#22CDA3]  from-[#8A33F2] text-white font-semibold rounded p-[2px] w-full md:w-1/2 ms-0 md:ms-2 mt-2 md:mt-0">
                    <input
                      type="email"
                      onChange={handleChange}
                      name="email"
                      value={user?.email || ""}
                      placeholder="Email"
                      className="placeholder:text-[#6D6C6C] flex w-full bg-black text-white rounded p-3"
                    />
                  </div>
                </div>
                <div className="flex w-full my-4">
                  <div className="bg-gradient-to-r to-[#22CDA3]  from-[#8A33F2] text-white font-semibold rounded p-[2px] w-full">
                    <textarea
                      type="text"
                      onChange={handleChange}
                      name="message"
                      value={user?.message || ""}
                      placeholder="Message"
                      className="placeholder:text-[#6D6C6C] flex w-full bg-black text-white rounded p-3 h-[250px] md:h-[300px]"
                    />
                  </div>
                </div>
                <div className="mt-4 flex gap-7 justify-center md:justify-end">
                  <button
                    type="submit"
                    className="flex items-center gap-2 tracking-wider font-medium font-Orbitron text-white bg-gradient-to-r to-[#22CDA3]  from-[#8A33F2] rounded-md text-md px-6 py-2 text-center"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
