import React, { useState } from "react";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <div className="shadow-sm shadow-[#9ED9C3] bg-black">
        <div className="container  flex flex-wrap items-center justify-between mx-auto">
          <a href="/virid">
            <img
              src={require("../images/Logo.png")}
              alt="Logo"
              className="lg:w-36 w-32"
            />
          </a>
          <div className="flex md:order-2 space-x-3 md:space-x-0  items-center ">
            {/* <button
              type="button"
              className="font-Orbitron text-white bg-gradient-to-r from-[#8A33F2]  to-[#22CDA3] rounded-lg text-sm px-5 py-4 text-center text-nowrap"
            >
              Lunch App
            </button> */}
            <button
              data-collapse-toggle="navbar-cta"
              type="button"
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200 "
              aria-controls="navbar-cta"
              aria-expanded={isMobileMenuOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
              isMobileMenuOpen ? "block" : "hidden"
            }`}
            id="navbar-cta"
          >
            <ul className="flex flex-col font-Orbitron p-4 md:p-0 mt-4  md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 ">
              <li>
                <a
                  href="/"
                  className="block py-2 px-3 md:p-0 text-gray-400"
                  aria-current="page"
                >
                  Home
                </a>
              </li>
              <li>
                <a href="/vision-mission" className="block py-2 px-3 md:p-0 text-gray-400">
                  Vision
                </a>
              </li>
              <li>
                <a
                  href="/why"
                  // target="_blank"
                  className="block py-2 px-3 md:p-0 text-gray-400"
                >
                  About Us
                </a>
              </li>
              <li>
                <a href="https://whitepaper.viridis.network/" target="_blank" className="block py-2 px-3 md:p-0 text-gray-400">
                    WhitePaper
                </a>
              </li>
              <li>
                <a
                  href="https://docs.viridis.network/roadmap/roadmap"
                  target="_blank"
                  className="block py-2 px-3 md:p-0 text-gray-400"
                >
                  Roadmaps
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
