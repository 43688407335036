import Home from "./components/home";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import VisionAndMission from "./components/VisionAndMission";
import PoolsToken from "./components/poolsToken";
import WhyViridis from "./components/whyViridis";
import Contact from "./components/contact";
import '../node_modules/react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/why",
    element: <WhyViridis />,
  },
  {
    path: "/vision-mission",
    element: <VisionAndMission />,
  },
  // {
  //   path: "/virid/pools-token",
  //   element: <PoolsToken />,
  // },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

function App() {
  return (
    <div className="bg-[#1E1E1E]">
      <Navbar />
      <RouterProvider router={router} />
      <Footer />
      <ToastContainer autoClose={1200} theme="dark" />
    </div>
  );
}

export default App;
