import React from "react";

const VisionAndMission = () => {
  return (
    <>
      <div className="relative">
        <div className="container mx-auto py-36 xl:px-72 lg:px-56 md:px-32 relative">
          <div className="font-Orbitron text-center font-black lg:text-5xl text-3xl text-transparent bg-clip-text bg-gradient-to-r from-[#22CDA3] via-[#4A91C2] to-[#8A33F2] ">
            Vision & Mission
          </div>
          <div className="absolute top-0 right-0">
            <img src={require("../images/section_decoration.png")} alt="hero" />
          </div>
        </div>
        <div className="hidden md:block absolute top-10">
          <img src={require("../images/top_circle.png")} alt="hero" />
        </div>
      </div>
      <div className="container relative mx-auto xxl:px-0 px-8">
        <div className="lg:grid grid-cols-2 items-center">
          <div className="grid gap-4 relative">
            <div className="font-Orbitron md:text-5xl text-3xl font-medium text-white">Vision</div>
            <div className="font-Orbitron text-xl text-white">
              Unlocking widespread climate impact.
            </div>
            <div className="font-Montserrat text-md  text-[#22CDA3] md:tracking-widest leading-7 text-justify">
              With time running out to bridge the emissions gap, the carbon market is pivotal in
              closing it. By constructing infrastructure that enhances connections between demand
              and supply, fosters trust, and enhances market transparency, we empower the market to
              scale up. The technology is available now— it's time to leverage it.
            </div>
            <div className="absolute bottom-0 left-36">
              <img src={require("../images/TwoSquare.png")} alt="hero" className="w-24" />
            </div>
          </div>
          <div className=" flex lg:justify-end justify-center">
            <img src={require("../images/energy-wind.png")} alt="hero" />
          </div>
        </div>
      </div>
      <div className="container mx-auto my-14 xl:px-0 px-8">
        <div className="grid relative lg:grid-cols-2 items-center">
          <div className="flex lg:justify-start justify-center lg:order-1 order-2">
            <img src={require("../images/velocity-for-environmental.png")} alt="hero" />
          </div>
          <div className="lg:order-2 order-1">
            <div className="absolute top-0">
              <img src={require("../images/TwoSquare.png")} alt="hero" />
            </div>
            <div className="font-Orbitron md:text-5xl text-3xl font-medium text-white">Mission</div>
            <div className="font-Orbitron md:text-xl my-4 text-white leading-7 ">
              Utilize blockchain technology to create infrastructure maximizing transparency,
              accessibility, and velocity for environmental commodities.
            </div>
            <div className="font-Montserrat text-md my-4 text-[#22CDA3] md:tracking-widest  leading-7 text-justify">
              Viridis allows a range of carbon offset suppliers to register their carbon credits to
              our protocol and deliver backing metadata to our EVM-compatible L1, generating a
              digitized carbon credit in the form of a NFT. These carbon credit wCRBN NFTs can then
              be fractionalized, traded akin to ERC20 tokens on our chain, and acquired by projects
              to be burnt into quantity specific carbon offset certificates.
            </div>
            <div className="font-Montserrat text-md my-4 text-[#22CDA3] md:tracking-widest  leading-7 text-justify">
              Thus, Viridis allows crypto projects to seamlessly acquire carbon credits, verified by
              details on-chain forming a live audit trail. and burn them to make their activities
              carbon neutral.
            </div>
          </div>
        </div>
      </div>
      <div className="relative xl:mt-0 md:mt-44">
        <div className="absolute -top-64 z-0 right-0 rotate-180 hidden md:block">
          <img src={require("../images/top_circle.png")} alt="hero" />
        </div>
        <div className="z-10 relative bg-gradient-to-r from-[#22CDA3] via-[#4A91C2]  to-[#8A33F2]  flex flex-col justify-center items-center py-5">
          <div className="text-center font-Orbitron text-white font-black xl:text-3xl md:text-xl my-4">
            The era of carbon markets has arrived – are you prepared?
          </div>
          <div className="text-white font-Orbitron text-md  rounded-lg border-4 inline-flex px-5 py-3">{`Let's proceed ->  `}</div>
        </div>
      </div>
    </>
  );
};

export default VisionAndMission;
