import React from "react";
import { useNavigate } from "react-router-dom";

const HeroPage = () => {
  const router = useNavigate();

  const handleClick = () => {
    router("/contact");
  };
  return (
    <div className="container mx-auto">
      <div className="flex relative justify-center items-center lg:h-screen my-10">
        <img
          className="lg:block hidden absolute"
          src={require("../images/Group_108.png")}
          alt="hero"
        />
        <img
          className="lg:block hidden absolute h-96 2xl:right-44 xl:right-24 right-0"
          src={require("../images/Hero_1_Mini_2.gif")}
          alt="hero"
        />
        <div className="lg:ms-12 lg:grid grid-cols-2 items-center lg:mx-0 mx-3 lg:mt-0  top-0">
          <div className="lg:hidden absolute">
            <img
              className="lg:hidden block z-0"
              src={require("../images/Hero_1_background.png")}
              alt="hero"
            />
          </div>
          <div className="flex flex-col z-10">
            <div className="text-4xl lg:text-6xl flex flex-wrap  font-Orbitron leading-tight font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#22CDA3] via-[#4A91C2] to-[#8A33F2]">
              Making ‘carbon neutral’ a reality for DeFi
            </div>
            <p className="font-Montserrat lg:text-md lg:w-1/2 lg:mt-10 mt-3 justify-center text-white text-justify">
              Viridis is building a novel protocol to deliver publicly auditable, on-chain, and
              easily transactable carbon credits, integrating DeFi seamlessly with the carbon credit
              industry.
            </p>
            <div className="mt-4 flex gap-7">
              {/* <button
                type="button"
                className=" flex items-center gap-2 tracking-wider font-Inter text-white bg-gradient-to-r to-[#8A33F2]  from-[#22CDA3] rounded-sm text-sm px-6 py-2 text-center"
              >
                
              </button> */}
              <a href="https://docs.viridis.network/roadmap/roadmap" target="_blank">
                <button
                  type="button"
                  className=" relative z-0 bg-gradient-to-r to-[#926ac3] va-[#22CDA3] from-[#74ad9f] text-white font-semibold rounded p-[1px]">
                    <span className="flex items-center gap-2 relative z-20 flex w-full bg-[#434343] text-white font-Orbitron rounded p-2 px-5">
                      Explore
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M11.5 0.99601C11.4978 0.719877 11.2721 0.497813 10.996 0.500016L6.49615 0.535926C6.22002 0.53813 5.99795 0.763767 6.00016 1.0399C6.00236 1.31603 6.228 1.5381 6.50413 1.53589L10.504 1.50397L10.5359 5.50385C10.5381 5.77998 10.7638 6.00205 11.0399 5.99984C11.316 5.99764 11.5381 5.772 11.5359 5.49587L11.5 0.99601ZM1.35636 11.5116L11.3564 1.35072L10.6436 0.64928L0.643636 10.8102L1.35636 11.5116Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                </button>
              </a>
              <button
                type="button"
                onClick={handleClick}
                className="tracking-wider font-Orbitron font-semibold text-white bg-gradient-to-r to-[#8A33F2]  from-[#22CDA3] rounded px-6 py-2 text-center"
              >
                Contact us
              </button>
              
            </div>
          </div>
          <div />
        </div>
      </div>
      <div className="flex justify-center lg:mb-0 mb-6">
        <img className="lg:hidden block" src={require("../images/Earth_with_BG.gif")} alt="hero" />
      </div>
    </div>
  );
};

export default HeroPage;
